<template>
  <ul
    class="photo-gallery-index"
    :class="{ 'list-side': this.type === 'side' }"
  >
    <li v-for="(gallery, index) of galleryList" :key="index">
      <router-link
        :to="{
          name: 'PhotoGalleryDetails',
          params: { slug: gallery.url },
        }"
        >{{ gallery.title }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "GalleryList",
  props: ["type"],
  data() {
    return {
      galleryList: [
        {
          title: "पूर्व तैयारी",
          url: "preparation",
        },
        {
          title: "विवरणिका",
          url: "prospectus",
        },
        {
          url: "day-1",
          title: "दिन 1",
        },
        {
          url: "day-2",
          title: "दिन 2",
        },
        {
          url: "day-3-6",
          title: "दिन 3-6",
        },
        {
          url: "day-7-8",
          title: "दिन 7-8",
        },
        {
          url: "day-9-10",
          title: "दिन 9-10",
        },
        {
          url: "day-11-13",
          title: "दिन 11-13",
        },
        {
          url: "day-14-16",
          title: "दिन 14-16",
        },
        {
          url: "day-17-19",
          title: "दिन 17-19",
        },
        {
          url: "day-20-23",
          title: "दिन 20-23",
        },
        {
          url: "day-24-29",
          title: "दिन 24-29",
        },
        {
          url: "day-30-32",
          title: "दिन 30-32",
        },
        {
          url: "day-33-37",
          title: "दिन 33-37",
        },
        {
          url: "day-38-40",
          title: "दिन 38-40",
        },
        {
          url: "day-41-43",
          title: "दिन 41-43",
        },
        {
          url: "day-44-46",
          title: "दिन 44-46",
        },
        {
          url: "day-47-50",
          title: "दिन 47-50",
        },
        {
          url: "day-51-53",
          title: "दिन 51-53",
        },
        {
          url: "day-54-57",
          title: "दिन 54-57",
        },
        {
          url: "day-58-61",
          title: "दिन 58-61",
        },
        {
          url: "day-62-64",
          title: "दिन-62-64",
        },
        {
          url: "day-65-68",
          title: "दिन-65-68",
        },
        {
          url: "day-69-71",
          title: "दिन 69-71",
        },
        {
          url: "conclusion",
          title: "समापन",
        },
      ],
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
  },
};
</script>
<style scoped lang="scss">
.photo-gallery-index {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    border-radius: 8px;
    border: 1px solid #02405f;
    margin: 8px;
    a {
      display: block;
      padding: 24px 40px;
      min-width: 150px;
      text-align: center;
      color: #02405f;
      text-decoration: none;
      &:hover,
      &.router-link-exact-active {
        background: #02405f;
        color: #fff;
      }
    }
  }

  &.list-side {
    flex-direction: column;
    width: 200px;
    min-width: 200px;
    border: 1px solid #02405f;
    margin-right: 24px;
    li {
      border: 0;
      border-bottom: 1px solid #02405f;
      border-radius: 0;
      margin: 0;
      &:last-child {
        border: 0;
      }
      a {
        padding: 8px;
      }
    }
  }
}
</style>
