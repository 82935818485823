<template>
  <div>
    <div
      @click="lightboxEffect(index, image)"
      v-for="(image, index) in images"
      :key="index"
      :style="{ 'background-image': `url(/img/${type}/${image})` }"
      class="light-box__thumbnail"
    ></div>
    <transition name="fade" mode="out-in">
      <div @click.stop="bg = !bg" class="light-box__bg" v-if="bg"></div>
    </transition>

    <div v-if="bg" class="main-box">
      <div class="light-box__close" @click.stop="bg = !bg">X</div>
      <p class="light-box__count" v-if="count">
        {{ currentImage + 1 }}/
        <span>{{ images.length }}</span>
      </p>
      <div
        @click="prev"
        class="light-box__prev light-box__btn"
        v-html="'<<'"
      ></div>

      <div v-if="bg" class="light-box__container">
        <transition name="fade" mode="out-in">
          <img
            :key="currentImage"
            :src="`/img/${type}/${selectedImage}`"
            class="light-box__container__img"
          />
        </transition>
      </div>

      <div class="light-box__caption" v-if="caption">
        <p v-if="captions[currentImage]">{{ captions[currentImage] }}</p>
      </div>

      <div @click="next" class="light-box__next light-box__btn">>></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bg: false,
      currentImage: 0,
      selectedImage: null,
      count: true,
      caption: false,
    };
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  methods: {
    lightboxEffect(index, image) {
      this.currentImage = index;
      this.selectedImage = image;
      this.bg = !this.bg;
    },
    next() {
      if (this.currentImage < this.images.length - 1) {
        this.currentImage++;
        this.selectedImage = this.images[this.currentImage];
      } else {
        this.currentImage = 0;
      }
    },
    prev() {
      if (this.currentImage > 0) {
        this.currentImage--;
        this.selectedImage = this.images[this.currentImage];
      } else {
        this.currentImage = this.images.length - 1;
      }
    },
  },
};
</script>

<style scopped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.main-box {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
}
.light-box {
  &__bg {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.89);
    z-index: 1000;
  }

  &__thumbnail {
    cursor: pointer;
    margin: 8px;
    width: 180px;
    height: 180px;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 8px;
    display: inline-block;
    background-size: cover;
    background-position: center center;
    @media screen and (max-width: 767px) {
      width: 45%;
      max-width: 180px;
      height: 150px;
    }
  }

  &__close {
    padding: 10px;
    position: absolute;
    right: 20px;
    top: 20px;
    background-image: url(/img/lightbox/close.svg);
    background-size: contain;
    background-position: center;
  }
  &__container {
    position: absolute;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 900px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-height: 800px;
    img {
      align-self: center;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  &__btn {
    background-size: contain;
    background-position: center;
    align-self: center;
  }

  &__close,
  &__btn {
    cursor: pointer;
    color: #fff;
    border: 1px solid #fff;
    width: 40px;
    height: 40px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__close,
  &__btn,
  &__caption,
  &__count {
    position: absolute;
    z-index: 3000;
    user-select: none;
  }

  &__next {
    background-image: url(/img/lightbox/next.svg);
    right: 20px;
  }
  &__prev {
    background-image: url(/img/lightbox/prev.svg);
    left: 20px;
  }
  &__next,
  &__prev {
    top: 50%;
    transform: translateY(-50%);
    @media screen and (max-width: 767px) {
      top: unset;
      bottom: 10px;
    }
  }

  &__caption {
    bottom: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 20px;
    justify-content: center;
  }

  &__count {
    left: 20px;
    font-size: 16px;
    color: #fff;
    top: 14px;
    padding: 0;
    margin: 0;
  }
}
</style>
