export const galleryData = {
  preparation: {
    dates:
      "विवेकानंद युवा महामंडल, यूथ होस्टेल्स एसोसिएशन ऑफ इण्डिया की मध्यप्रदेश शाखा, ऑल इण्डिया इन्श्युरेंस एम्प्लॉईज़ एसोसिएशन के पदाधिकारियों एवं रामकृष्ण मिशन के स्वामीजी के साथ पूर्व तैयारियो की बैठक",
    images: [
      "Twitter.jpg",
      "Facebook.jpg",
      "31.jpg",
      "30.jpg",
      "29.jpg",
      "28.jpg",
      "27.jpg",
      "26.jpg",
      "25.jpg",
      "24.jpg",
      "23.jpg",
      "22.jpg",
      "21.jpg",
      "20.jpg",
      "19.jpg",
      "18.jpg",
      "17.jpg",
      "16.jpg",
      "15.jpg",
      "14.jpg",
      "13.jpg",
      "12.jpg",
      "11.jpg",
      "10.jpg",
      "9.jpg",
      "8.jpg",
      "7.jpg",
      "6.jpg",
      "5-1.jpg",
      "4-1.jpg",
      "3-1.jpg",
      "2-1.jpg",
      "1-1.jpg",
    ],
  },
  prospectus: {
    dates:
      "भारत परिक्रमा के दौरान मार्ग मे वितरित किये गए यात्रा विवरणिका पत्र",
    images: ["brochure2.jpg", "brochure1.jpg"],
  },
  "day-1": {
    dates: "दिन 1 यात्रा प्रारम्भ (दिनांक 28 अप्रैल 2013)",
    description: "इंदौर (म.प्र.) से उज्जैन, देवास होते हुए भोपाल (म.प्र.)",
    images: [
      "P1060589.jpg",
      "P1060584.jpg",
      "P1060570.jpg",
      "P1060564.jpg",
      "P1060490.jpg",
      "DSC00143.jpg",
      "DSC00141.jpg",
      "DSC00131.jpg",
      "DSC00111.jpg",
      "DSC00101.jpg",
      "DSC00099.jpg",
      "DSC00089.jpg",
      "DSC00073.jpg",
    ],
  },
  "day-2": {
    dates: "दिन 2 (दिनांक 29 अप्रैल 2013)",
    description: "भोपाल (म.प्र.) से होशंगाबाद, इटारसी होते हुए बेतुल (म.प्र.)",
    images: [
      "P1060700.jpg",
      "P1060696.jpg",
      "P1060695.jpg",
      "P1060692.jpg",
      "P1060681.jpg",
      "P1060677.jpg",
      "P1060673.jpg",
      "P1060662.jpg",
      "P1060658.jpg",
      "P1060654.jpg",
      "P1060649.jpg",
      "P1060642.jpg",
      "P1060639.jpg",
      "P1060633.jpg",
      "P1060628.jpg",
      "P1060626.jpg",
      "P1060619.jpg",
      "P1060611.jpg",
      "P1060596.jpg",
      "P1060595.jpg",
      "P1060594.jpg",
      "DSC06942.jpg",
    ],
  },
  "day-3-6": {
    dates: "दिन 3 से 6 (दिनांक 30 अप्रैल से 3 मई 2013)",
    description:
      "बेतुल (म.प्र.) से नागपुर, भंडारा, राजनान्दगाँव, भिलाई, रायपुर (छ.ग.), सम्बलपुर, केओंझर(उड़ीसा), खरगरपुर होते हुए कोलकाता(प.ब.)",
    images: [
      "P1060728.jpg",
      "P1060725.jpg",
      "P1060718.jpg",
      "P1060717.jpg",
      "P1060715.jpg",
      "P1060714.jpg",
      "P1060709.jpg",
      "P1060705.jpg",
      "P1060704.jpg",
      "DSC07024.jpg",
      "DSC07023.jpg",
      "DSC07010.jpg",
      "DSC07009.jpg",
      "DSC07006.jpg",
      "DSC07004.jpg",
      "DSC06993.jpg",
      "DSC06990.jpg",
      "DSC06989.jpg",
      "DSC06984.jpg",
      "DSC06983.jpg",
      "DSC06979.jpg",
      "DSC06974.jpg",
      "DSC06972.jpg",
      "DSC06970.jpg",
      "DSC06969.jpg",
      "DSC06967.jpg",
      "DSC06964.jpg",
      "DSC06961.jpg",
      "DSC06957.jpg",
      "DSC06947.jpg",
    ],
  },
  "day-7-8": {
    dates: "दिन 7 से 8 (दिनांक 4 मई से 5 मई 2013)",
    description:
      "कोलकाता (प.ब.), बेलुर मठ से रानाघाट, बेरहामपुर, इंग्लिशबाजार होते हुए रायगंज (प.ब.)",
    images: [
      "P1060748.jpg",
      "P1060746.jpg",
      "P1060744.jpg",
      "P1060743.jpg",
      "P1060741.jpg",
      "DSC07068.jpg",
      "DSC07064.jpg",
      "DSC07062.jpg",
      "DSC07059.jpg",
      "DSC07057.jpg",
      "DSC07056.jpg",
      "DSC07050.jpg",
      "DSC07049.jpg",
      "DSC07048.jpg",
      "DSC07045.jpg",
      "DSC07044.jpg",
      "DSC07043.jpg",
      "DSC07042.jpg",
      "DSC07033.jpg",
      "DSC07031.jpg",
      "DSC07026.jpg",
    ],
  },
  "day-9-10": {
    dates: "दिन 9 से 10 (दिनांक 6 मई से 7 मई 2013)",
    description:
      "रायगंज (प.ब.) से किशनगंज, इस्लामपुर, सिलीगुड़ी, धूपगुरी (प.ब.), मथुराबागान, अलीपुरद्वार, गोसईगाँव होते हुए बोंगईगांव(आसाम)",
    images: [
      "P1060815.jpg",
      "P1060807.jpg",
      "P1060806.jpg",
      "P1060786.jpg",
      "P1060785.jpg",
      "P1060774.jpg",
      "P1060772.jpg",
      "P1060769.jpg",
      "P1060766.jpg",
      "P1060763.jpg",
      "P1060759.jpg",
      "P1060758.jpg",
      "P1060756.jpg",
      "P1060755.jpg",
      "P1060754.jpg",
      "DSC07100.jpg",
      "DSC07098.jpg",
      "DSC07096.jpg",
      "DSC07095.jpg",
      "DSC07093.jpg",
      "DSC07089.jpg",
      "DSC07088.jpg",
      "DSC07077.jpg",
      "DSC07070.jpg",
      "DSC07069.jpg",
    ],
  },
  "day-11-13": {
    dates: "दिन 11 से 13 (दिनांक 8 मई से 10 मई 2013)",
    description:
      "बोंगईगांव (आसाम) से नालबारी, ओरांग, तेजपुर (आसाम), जमुरीघाट, गोहपुर, इटानगर(अरु.प्र.), नॉर्थ लखीमपुर, धेमाजी होते हुए पासीघाट(अरु.प्र.)",
    images: [
      "P1070052.jpg",
      "P1070051.jpg",
      "P1070049.jpg",
      "P1070048.jpg",
      "P1070040.jpg",
      "P1070037.jpg",
      "P1070018.jpg",
      "P1070011.jpg",
      "P1070009.jpg",
      "P1070008.jpg",
      "P1070001.jpg",
      "P1060997.jpg",
      "P1060984.jpg",
      "P1060937.jpg",
      "P1060917.jpg",
      "P1060914.jpg",
      "P1060912.jpg",
      "P1060910.jpg",
      "P1060908.jpg",
      "P1060894.jpg",
      "P1060889.jpg",
      "DSC07140.jpg",
      "DSC07112.jpg",
      "DSC07111.jpg",
      "DSC07106.jpg",
      "DSC07104.jpg",
    ],
  },
  "day-14-16": {
    dates: "दिन 14 से 16 (दिनांक 11 मई से 13 मई 2013)",
    description:
      "पासीघाट (अरु.प्र.) से साड़ियाघाट, तिनसुकिया, डिब्रुगढ़, सिवसागर, जोरहाट(आसाम), गोलाघाट, दिमापुर होते हुए कोहिमा(नागा.)",
    images: [
      "P1070300.jpg",
      "P1070299.jpg",
      "P1070292.jpg",
      "P1070291.jpg",
      "P1070289.jpg",
      "P1070288.jpg",
      "P1070282.jpg",
      "P1070271.jpg",
      "P1070270.jpg",
      "P1070268.jpg",
      "P1070244.jpg",
      "P1070240.jpg",
      "P1070234.jpg",
      "P1070232.jpg",
      "P1070231.jpg",
      "P1070230.jpg",
      "P1070224.jpg",
      "P1070219.jpg",
      "P1070218.jpg",
      "P1070208.jpg",
      "P1070204.jpg",
      "P1070183.jpg",
      "P1070171.jpg",
      "P1070148.jpg",
      "P1070135.jpg",
      "P1070128.jpg",
      "P1070127.jpg",
      "P1070118.jpg",
      "P1070116.jpg",
      "P1070114.jpg",
      "P1070101.jpg",
    ],
  },
  "day-17-19": {
    dates: "दिन 17 से 19 (दिनांक 14 मई से 16 मई 2013)",
    description:
      "कोहिमा (नागा.) से सेनापति, सेकमाई बाजार, इम्फ़ाल (मणि.), इम्फ़ाल- सिल्चर मार्ग, शांतिखुनौ(मणि.) होते हुए सिल्चर(आसाम)",
    images: [
      "P1070428.jpg",
      "P1070427.jpg",
      "P1070424.jpg",
      "P1070420.jpg",
      "P1070417.jpg",
      "P1070414.jpg",
      "P1070411.jpg",
      "P1070410.jpg",
      "P1070408.jpg",
      "P1070395.jpg",
      "P1070394.jpg",
      "P1070377.jpg",
      "P1070375.jpg",
      "P1070374.jpg",
      "P1070362.jpg",
      "P1070356.jpg",
      "P1070353.jpg",
      "P1070349.jpg",
      "P1070334.jpg",
      "P1070326.jpg",
      "P1070324.jpg",
      "P1070322.jpg",
      "P1070320.jpg",
      "P1070315.jpg",
      "P1070312.jpg",
      "P1070309.jpg",
    ],
  },
  "day-20-23": {
    dates: "दिन 20 से 23 (दिनांक 17 मई से 20 मई 2013)",
    description:
      "सिल्चर (आसाम) से वैरेंगटे (मिज़ो.), सिल्चर, करीमगंज (आसाम), तेलियामुरा, अगरतला (त्रिपुरा),तेलियामुरा, पानीसागर, करीमगंज(आसाम), बदरपुर, जोवाई होते हुए शिलोंग(मेघा.)",
    images: [
      "P1070617.jpg",
      "P1070608.jpg",
      "P1070607.jpg",
      "P1070559.jpg",
      "P1070552.jpg",
      "P1070548.jpg",
      "P1070541.jpg",
      "P1070540.jpg",
      "P1070539.jpg",
      "P1070530.jpg",
      "P1070508.jpg",
      "P1070500.jpg",
      "P1070496.jpg",
      "P1070449.jpg",
      "DSC07465.jpg",
      "DSC07464.jpg",
      "DSC07457.jpg",
      "DSC07455.jpg",
      "DSC07454.jpg",
      "DSC07450.jpg",
      "DSC07447.jpg",
      "DSC07442.jpg",
      "DSC07433.jpg",
      "DSC07417.jpg",
      "DSC07416.jpg",
      "DSC07402.jpg",
      "DSC07385.jpg",
      "DSC07376.jpg",
      "DSC07374.jpg",
      "DSC07365.jpg",
      "DSC07360.jpg",
      "DSC07354.jpg",
      "DSC07351.jpg",
      "DSC07350.jpg",
      "DSC07342.jpg",
      "DSC07324.jpg",
      "DSC07293.jpg",
      "DSC07290.jpg",
      "DSC07264.jpg",
      "DSC07230.jpg",
      "DSC07220.jpg",
      "DSC07213.jpg",
      "DSC07210.jpg",
    ],
  },
  "day-24-29": {
    dates: "दिन 24 से 29 (दिनांक 21 मई से 26 मई 2013)",
    description:
      "शिलोंग (मेघा.) से गुवाहाटी, छापर (आसाम), कूच-बिहार, मथुराबागान, मालबाजार, रांगपो, गंगटोक (सिक्किम), घूम, दार्जिलिंग (प.ब.), नक्सलबारी, बसंतपुर, फोरबिसगंज, सिमराही (बिहार), दरभंगा, गोपालगंज, कुशीनगर, गोरखपुर, बस्ती, अयोध्या होते हुए लखनऊ (उ.प्र.)",
    images: [
      "P1070800.jpg",
      "P1070726.jpg",
      "P1070710.jpg",
      "P1070700.jpg",
      "P1070646.jpg",
      "P1070645.jpg",
      "DSC07995.jpg",
      "DSC07993.jpg",
      "DSC07992.jpg",
      "DSC07990.jpg",
      "DSC07961.jpg",
      "DSC07957.jpg",
      "DSC07956.jpg",
      "DSC07948.jpg",
      "DSC07946.jpg",
      "DSC07944.jpg",
      "DSC07932.jpg",
      "DSC07924.jpg",
      "DSC07908.jpg",
      "DSC07900.jpg",
      "DSC07899.jpg",
      "DSC07897.jpg",
      "DSC07896.jpg",
      "DSC07878.jpg",
      "DSC07876.jpg",
      "DSC07872.jpg",
      "DSC07850.jpg",
      "DSC07841.jpg",
      "DSC07835.jpg",
      "DSC07833.jpg",
      "DSC07824.jpg",
      "DSC07822.jpg",
      "DSC07819.jpg",
      "DSC07786.jpg",
      "DSC07781.jpg",
      "DSC07753.jpg",
      "DSC07750.jpg",
      "DSC07745.jpg",
      "DSC07724.jpg",
      "DSC07712.jpg",
      "DSC07708.jpg",
      "DSC07705.jpg",
      "DSC07697.jpg",
      "DSC07681.jpg",
      "DSC07679.jpg",
      "DSC07667.jpg",
      "DSC07658.jpg",
      "DSC07653.jpg",
      "DSC07652.jpg",
      "DSC07651.jpg",
      "DSC07622.jpg",
      "DSC07619.jpg",
      "DSC07603.jpg",
      "DSC07600.jpg",
      "DSC07599.jpg",
      "DSC07590.jpg",
      "DSC07584.jpg",
      "DSC07568.jpg",
      "DSC07555.jpg",
      "DSC07552.jpg",
      "DSC07542.jpg",
      "DSC07534.jpg",
      "DSC07531.jpg",
      "DSC07530.jpg",
      "DSC07511.jpg",
      "DSC07510.jpg",
      "DSC07485.jpg",
      "DSC07482.jpg",
      "DSC07466.jpg",
    ],
  },
  "day-30-32": {
    dates: "दिन 30 से 32 (दिनांक 27 मई से 29 मई 2013)",
    description:
      "लखनऊ (उ.प्र.) से सीतापुर, शाहजहाँपुर, बरेली (उ.प्र.), बदायु, सिकंदरा, अलीगढ़, टप्पल होते हुए नई दिल्ली",
    images: [
      "P1070872.jpg",
      "P1070871.jpg",
      "P1070864.jpg",
      "P1070858.jpg",
      "P1070854.jpg",
      "DSC08079.jpg",
      "DSC08074.jpg",
      "DSC08073.jpg",
      "DSC08070.jpg",
      "DSC08056.jpg",
      "DSC08053.jpg",
      "DSC08047.jpg",
      "DSC08045.jpg",
      "DSC08041.jpg",
      "DSC08039.jpg",
      "DSC08037.jpg",
      "DSC08027.jpg",
      "DSC08026.jpg",
      "DSC08017.jpg",
      "DSC08016.jpg",
    ],
  },
  "day-33-37": {
    dates: "दिन 33 से 37 (दिनांक 30 मई से 3 जून 2013)",
    description:
      "नई दिल्ली से गाजियाबाद, मोरादाबाद, रामपुर (उ.प्र.), रुद्रपुर, हल्द्वानी, अल्मोरा, रानीखेत, कर्णप्रयाग, चमोली, जोशीमठ, बद्रीनाथ, पिपलकोटी, रुद्रप्रयाग, श्रीनगर, ऋषिकेश होते हुए देहरादून (उ.ख.)",
    images: [
      "P1080106.jpg",
      "P1080104.jpg",
      "P1080048.jpg",
      "P1080030.jpg",
      "P1080029.jpg",
      "P1080025.jpg",
      "P1070985.jpg",
      "P1070972.jpg",
      "P1070948.jpg",
      "DSC08366.jpg",
      "DSC08308.jpg",
      "DSC08306.jpg",
      "DSC08286.jpg",
      "DSC08280.jpg",
      "DSC08274.jpg",
      "DSC08261.jpg",
      "DSC08256.jpg",
      "DSC08247.jpg",
      "DSC08243.jpg",
      "DSC08231.jpg",
      "DSC08229.jpg",
      "DSC08213.jpg",
      "DSC08203.jpg",
      "DSC08179.jpg",
      "DSC08166.jpg",
      "DSC08153.jpg",
      "DSC08142.jpg",
      "DSC08132.jpg",
      "DSC08130.jpg",
      "DSC08120.jpg",
      "DSC08113.jpg",
      "DSC08098.jpg",
      "DSC08096.jpg",
      "DSC08088.jpg",
      "DSC00243.jpg",
      "DSC00239.jpg",
      "DSC00229.jpg",
      "DSC00227.jpg",
      "DSC00221.jpg",
      "DSC00219.jpg",
      "DSC00212.jpg",
      "DSC00211.jpg",
      "DSC00206.jpg",
      "DSC00200.jpg",
      "DSC00191.jpg",
      "DSC00171.jpg",
      "DSC00169.jpg",
      "DSC00160.jpg",
      "DSC00157.jpg",
      "DSC00155.jpg",
    ],
  },
  "day-38-40": {
    dates: "दिन 38 से 40 (दिनांक 4 जून से 6 जून 2013)",
    description:
      "देहरादून से पोंटासाहिब, चंडीगढ़, आनंदपुर साहिब (पंजाब), किरतपुर, बिलासपुर, मंडी, भुंतर, कुल्लू, मनाली, रोहतांग पास होते हुए केलौंग (हि.प्र.)",
    images: [
      "P1080237.jpg",
      "P1080226.jpg",
      "P1080214.jpg",
      "P1080204.jpg",
      "P1080203.jpg",
      "P1080199.jpg",
      "P1080192.jpg",
      "P1080182.jpg",
      "P1080172.jpg",
      "P1080165.jpg",
      "P1080163.jpg",
      "P1080152.jpg",
      "P1080148.jpg",
      "DSC08547.jpg",
      "DSC08545.jpg",
      "DSC08533.jpg",
      "DSC08523.jpg",
      "DSC08521.jpg",
      "DSC08516.jpg",
      "DSC08501.jpg",
      "DSC08494.jpg",
      "DSC08491.jpg",
      "DSC08479.jpg",
      "DSC08472.jpg",
      "DSC08470.jpg",
      "DSC08467.jpg",
      "DSC08435.jpg",
      "DSC08429.jpg",
      "DSC08421.jpg",
      "DSC08387.jpg",
      "DSC08374.jpg",
      "DSC08372.jpg",
      "DSC00294.jpg",
      "DSC00288.jpg",
      "DSC00287.jpg",
      "DSC00283.jpg",
      "DSC00282.jpg",
      "DSC00269.jpg",
      "DSC00263.jpg",
      "DSC00258.jpg",
      "DSC00257.jpg",
    ],
  },
  "day-41-43": {
    dates: "दिन 41 से 43 (दिनांक 7 जून से 9 जून 2013)",
    description:
      "केलौंग (हि.प्र.) से पाँग, लेह, खार्दुंग-ला, लेह, लामायुरु होते हुए कारगिल (ज.क.)",
    images: [
      "P1080454.jpg",
      "P1080453.jpg",
      "P1080394.jpg",
      "P1080360.jpg",
      "P1080345.jpg",
      "P1080341.jpg",
      "P1080338.jpg",
      "P1080316.jpg",
      "P1080302.jpg",
      "P1080299.jpg",
      "P1080280.jpg",
      "P1080267.jpg",
      "P1080266.jpg",
      "P1080263.jpg",
      "DSC09017.jpg",
      "DSC09014.jpg",
      "DSC09012.jpg",
      "DSC08996.jpg",
      "DSC08984.jpg",
      "DSC08983.jpg",
      "DSC08976.jpg",
      "DSC08969.jpg",
      "DSC08968.jpg",
      "DSC08962.jpg",
      "DSC08956.jpg",
      "DSC08954.jpg",
      "DSC08951.jpg",
      "DSC08947.jpg",
      "DSC08940.jpg",
      "DSC08935.jpg",
      "DSC08932.jpg",
      "DSC08925.jpg",
      "DSC08919.jpg",
      "DSC08917.jpg",
      "DSC08912.jpg",
      "DSC08908.jpg",
      "DSC08881.jpg",
      "DSC08879.jpg",
      "DSC08873.jpg",
      "DSC08854.jpg",
      "DSC08851.jpg",
      "DSC08842.jpg",
      "DSC08835.jpg",
      "DSC08832.jpg",
      "DSC08821.jpg",
      "DSC08817.jpg",
      "DSC08804.jpg",
      "DSC08802.jpg",
      "DSC08793.jpg",
      "DSC08781.jpg",
      "DSC08771.jpg",
      "DSC08769.jpg",
      "DSC08748.jpg",
      "DSC08731.jpg",
      "DSC08728.jpg",
      "DSC08723.jpg",
      "DSC08718.jpg",
      "DSC08697.jpg",
      "DSC08692.jpg",
      "DSC08690.jpg",
      "DSC08686.jpg",
      "DSC08672.jpg",
      "DSC08664.jpg",
      "DSC08657.jpg",
      "DSC08655.jpg",
      "DSC08653.jpg",
      "DSC08644.jpg",
      "DSC08639.jpg",
      "DSC08620.jpg",
      "DSC08618.jpg",
      "DSC08608.jpg",
      "DSC08596.jpg",
      "DSC08588.jpg",
      "DSC08572.jpg",
      "DSC08568.jpg",
      "DSC08565.jpg",
      "DSC08562.jpg",
      "DSC00387.jpg",
      "DSC00380.jpg",
      "DSC00378.jpg",
      "DSC00377.jpg",
      "DSC00373.jpg",
      "DSC00372.jpg",
      "DSC00371.jpg",
      "DSC00368.jpg",
      "DSC00367.jpg",
      "DSC00366.jpg",
      "DSC00365.jpg",
      "DSC00364.jpg",
      "DSC00361.jpg",
      "DSC00359.jpg",
      "DSC00338.jpg",
      "DSC00296.jpg",
    ],
  },
  "day-44-46": {
    dates: "दिन 44 से 46 (दिनांक 10 जून से 12 जून 2013)",
    description:
      "कारगिल (ज.क.) से श्रीनगर, अनंतनाग, रामबन, पटनीटाप, ऊधमपुर, जम्मू, लखेनपुर (ज.क.), कैलाशपुर, गुरदासपुर, डेरा बाबानानक, अटारी, अमृतसर, तरण-तारण होते हुए झिरा (पंजाब)",
    images: [
      "P1080598.jpg",
      "P1080591.jpg",
      "P1080589.jpg",
      "P1080585.jpg",
      "P1080583.jpg",
      "DSC09162.jpg",
      "DSC09157.jpg",
      "DSC09154.jpg",
      "DSC09151.jpg",
      "DSC09147.jpg",
      "DSC09145.jpg",
      "DSC09140.jpg",
      "DSC09139.jpg",
      "DSC09134.jpg",
      "DSC09132.jpg",
      "DSC09130.jpg",
      "DSC09125.jpg",
      "DSC09119.jpg",
      "DSC09110.jpg",
      "DSC09108.jpg",
      "DSC09107.jpg",
      "DSC09104.jpg",
      "DSC09094.jpg",
      "DSC09089.jpg",
      "DSC09078.jpg",
      "DSC09075.jpg",
      "DSC09060.jpg",
      "DSC09057.jpg",
      "DSC09054.jpg",
      "DSC09051.jpg",
      "DSC09049.jpg",
      "DSC09048.jpg",
      "DSC09046.jpg",
      "DSC09040.jpg",
      "DSC09037.jpg",
      "DSC09036.jpg",
      "DSC09034.jpg",
      "DSC09032.jpg",
      "DSC09031.jpg",
      "DSC09021.jpg",
      "DSC00440.jpg",
      "DSC00433.jpg",
      "DSC00423.jpg",
      "DSC00407.jpg",
      "DSC00402.jpg",
      "DSC00400.jpg",
      "DSC00394.jpg",
      "DSC00393.jpg",
      "DSC00389.jpg",
    ],
  },
  "day-47-50": {
    dates: "दिन 47 से 50 (दिनांक 13 जून से 16 जून 2013)",
    description:
      "झिरा (पंजाब) से अबोहर, श्रीगंगानगर, सुरतगढ़, महाजन, बीकानेर, फलोदी, रामदेवरा, जैसलमर, बारमेड, सांचोर (राज.), थराड, सुइगाम, भचाउ होते हुए भुज़ (गुज.)",
    images: [
      "P1080790.jpg",
      "P1080789.jpg",
      "P1080788.jpg",
      "P1080754.jpg",
      "P1080749.jpg",
      "P1080727.jpg",
      "P1080598-1.jpg",
      "DSC09429.jpg",
      "DSC09427.jpg",
      "DSC09424.jpg",
      "DSC09417.jpg",
      "DSC09408.jpg",
      "DSC09406.jpg",
      "DSC09405.jpg",
      "DSC09382.jpg",
      "DSC09368.jpg",
      "DSC09366.jpg",
      "DSC09364.jpg",
      "DSC09354.jpg",
      "DSC09350.jpg",
      "DSC09349.jpg",
      "DSC09348.jpg",
      "DSC09344.jpg",
      "DSC09332.jpg",
      "DSC09330.jpg",
      "DSC09328.jpg",
      "DSC09323.jpg",
      "DSC09309.jpg",
      "DSC09302.jpg",
      "DSC09298.jpg",
      "DSC09292.jpg",
      "DSC09290.jpg",
      "DSC09281.jpg",
      "DSC09275.jpg",
      "DSC09270.jpg",
      "DSC09259.jpg",
      "DSC09257.jpg",
      "DSC09250.jpg",
      "DSC09245.jpg",
      "DSC09244.jpg",
      "DSC09240.jpg",
      "DSC09239.jpg",
      "DSC09231.jpg",
      "DSC09227.jpg",
      "DSC09226.jpg",
      "DSC09216.jpg",
      "DSC09215.jpg",
      "DSC09201.jpg",
      "DSC09196.jpg",
      "DSC09191.jpg",
      "DSC09190.jpg",
      "DSC09186.jpg",
      "DSC09184.jpg",
      "DSC09178.jpg",
      "DSC09167.jpg",
      "DSC09163.jpg",
      "DSC00490.jpg",
      "DSC00488.jpg",
      "DSC00486.jpg",
      "DSC00466.jpg",
      "DSC00449.jpg",
    ],
  },
  "day-51-53": {
    dates: "दिन 51 से 53 (दिनांक 17 जून से 19 जून 2013)",
    description:
      "भुज़ (गुज.) से नखत्राणा, लखपत, नारायण सरोवर, नलिया, मांडवी, अंजड़, भचाउ, जामनगर, द्वारका, पोरबंदर, सोमनाथ (गुज.) होते हुए दीव (द.दी.)",
    images: [
      "P1090008.jpg",
      "P1080987.jpg",
      "P1080924.jpg",
      "DSC09675.jpg",
      "DSC09673.jpg",
      "DSC09672.jpg",
      "DSC09670.jpg",
      "DSC09668.jpg",
      "DSC09660.jpg",
      "DSC09656.jpg",
      "DSC09655.jpg",
      "DSC09652.jpg",
      "DSC09649.jpg",
      "DSC09648.jpg",
      "DSC09647.jpg",
      "DSC09645.jpg",
      "DSC09631.jpg",
      "DSC09630.jpg",
      "DSC09628.jpg",
      "DSC09625.jpg",
      "DSC09623.jpg",
      "DSC09621.jpg",
      "DSC09620.jpg",
      "DSC09619.jpg",
      "DSC09617.jpg",
      "DSC09615.jpg",
      "DSC09614.jpg",
      "DSC09612.jpg",
      "DSC09611.jpg",
      "DSC09605.jpg",
      "DSC09598.jpg",
      "DSC09593.jpg",
      "DSC09591.jpg",
      "DSC09587.jpg",
      "DSC09585.jpg",
      "DSC09583.jpg",
      "DSC09554.jpg",
      "DSC09551.jpg",
      "DSC09550.jpg",
      "DSC09547.jpg",
      "DSC09546.jpg",
      "DSC09537.jpg",
      "DSC09530.jpg",
      "DSC09524.jpg",
      "DSC09521.jpg",
      "DSC09517.jpg",
      "DSC09516.jpg",
      "DSC09515.jpg",
      "DSC09514.jpg",
      "DSC09513.jpg",
      "DSC09509.jpg",
      "DSC09500.jpg",
      "DSC09498.jpg",
      "DSC09488.jpg",
      "DSC09478.jpg",
      "DSC09470.jpg",
      "DSC09467.jpg",
      "DSC09458.jpg",
      "DSC09452.jpg",
      "DSC09450.jpg",
      "DSC09447.jpg",
      "DSC09446.jpg",
      "DSC00608.jpg",
      "DSC00601.jpg",
      "DSC00590.jpg",
      "DSC00588.jpg",
      "DSC00587.jpg",
      "DSC00579.jpg",
      "DSC00578.jpg",
      "DSC00576.jpg",
      "DSC00563.jpg",
      "DSC00561.jpg",
      "DSC00555.jpg",
      "DSC00553.jpg",
      "DSC00552.jpg",
      "DSC00550.jpg",
      "DSC00547.jpg",
      "DSC00542.jpg",
      "DSC00537.jpg",
      "DSC00536.jpg",
      "DSC00532.jpg",
      "DSC00521.jpg",
      "DSC00516.jpg",
      "DSC00515.jpg",
    ],
  },
  "day-54-57": {
    dates: "दिन 54 से 57 (दिनांक 20 जून से 23 जून 2013)",
    description:
      "दीव (द.दी.) से महुवा, भावनगर, बरोदा, भरूच, वापी (गुज़.), दमन, सिलवासा (दा.न.ह.), ठाणे, पनवेल, महाड़, चिपलून (महा.) होते हुए पंजिम (गोवा)",
    images: [
      "P1090173.jpg",
      "P1090172.jpg",
      "P1090115.jpg",
      "P1090114.jpg",
      "P1090070.jpg",
      "P1090050.jpg",
      "P1090049.jpg",
      "P1090046.jpg",
      "DSC09842.jpg",
      "DSC09841.jpg",
      "DSC09838.jpg",
      "DSC09826.jpg",
      "DSC09802.jpg",
      "DSC09798.jpg",
      "DSC09766.jpg",
      "DSC09757.jpg",
      "DSC09754.jpg",
      "DSC09749.jpg",
      "DSC09739.jpg",
      "DSC09735.jpg",
      "DSC09730.jpg",
      "DSC09724.jpg",
      "DSC09723.jpg",
      "DSC09720.jpg",
      "DSC09713.jpg",
      "DSC09712.jpg",
      "DSC09708.jpg",
      "DSC09706.jpg",
      "DSC09700.jpg",
      "DSC09696.jpg",
      "DSC09695.jpg",
      "DSC09694.jpg",
      "DSC00621.jpg",
      "DSC00618.jpg",
      "DSC00613.jpg",
      "DSC00611.jpg",
    ],
  },
  "day-58-61": {
    dates: "दिन 58 से 61 (दिनांक 24 जून से 27 जून 2013)",
    description:
      "पंजिम (गोवा) से अंकोला, भटकल, उडुपी, मेंगलोर (कर्ना.), कसर्गोड़, माहे, कोझिगोड़, वेलेंचेरी, थ्रिसुर, कोच्चि, अलप्पुझा, ओचिरा (केरल), कोल्लम, थिरुवनंतपुरम, नागरकोइल होते हुए कन्याकुमारी (त.ना.)",
    images: [
      "P1090501.jpg",
      "P1090497.jpg",
      "P1090496.jpg",
      "P1090491.jpg",
      "P1090489.jpg",
      "P1090485.jpg",
      "P1090476.jpg",
      "P1090475.jpg",
      "P1090473.jpg",
      "P1090444.jpg",
      "P1090380.jpg",
      "P1090364.jpg",
      "P1090363.jpg",
      "P1090309.jpg",
      "P1090283.jpg",
      "P1090272.jpg",
      "P1090252.jpg",
      "DSC09993.jpg",
      "DSC09992.jpg",
      "DSC09990.jpg",
      "DSC09988.jpg",
      "DSC09987.jpg",
      "DSC09986.jpg",
      "DSC09983.jpg",
      "DSC09981.jpg",
      "DSC09968.jpg",
      "DSC09952.jpg",
      "DSC09947.jpg",
      "DSC09916.jpg",
      "DSC09915.jpg",
      "DSC09913.jpg",
      "DSC09909.jpg",
      "DSC09908.jpg",
      "DSC09907.jpg",
      "DSC09906.jpg",
      "DSC09905.jpg",
      "DSC09887.jpg",
      "DSC09880.jpg",
      "DSC09871.jpg",
      "DSC09870.jpg",
      "DSC00664.jpg",
      "DSC00661.jpg",
      "DSC00653.jpg",
      "DSC00652.jpg",
      "DSC00648.jpg",
      "DSC00632.jpg",
      "DSC00631.jpg",
      "DSC00621-1.jpg",
      "DSC00618-1.jpg",
      "DSC00613-1.jpg",
      "DSC00611-1.jpg",
      "DSC00608-1.jpg",
    ],
  },
  "day-62-64": {
    dates: "दिन 62 से 64 (दिनांक 28 जून से 30 जून 2013)",
    description:
      "कन्याकुमारी (त.ना.) से तिरुनेलवेली, तूथूकुडी, रामानाथपुरम, रामेश्वरम, चिदम्बरम, पोण्डेचेरी, चेन्नई (त.ना.), नेल्लोरे होते हुए ओंगोले (आ.प्र.)",
    images: [
      "P1090677.jpg",
      "P1090675.jpg",
      "P1090623.jpg",
      "P1090582.jpg",
      "P1090533.jpg",
      "P1090515.jpg",
      "P1090509.jpg",
      "DSC00708.jpg",
      "DSC00702.jpg",
      "DSC00698.jpg",
      "DSC00697.jpg",
      "DSC00691.jpg",
      "DSC00689.jpg",
      "DSC00686.jpg",
      "DSC00682.jpg",
      "DSC00681.jpg",
      "DSC00677.jpg",
      "DSC00673.jpg",
      "DSC00190.jpg",
      "DSC00159.jpg",
      "DSC00133.jpg",
      "DSC00131-1.jpg",
      "DSC00126.jpg",
      "DSC00120.jpg",
      "DSC00113.jpg",
      "DSC00110.jpg",
      "DSC00106.jpg",
      "DSC00104.jpg",
      "DSC00103.jpg",
      "DSC00089-1.jpg",
      "DSC00088.jpg",
      "DSC00085.jpg",
      "DSC00081.jpg",
      "DSC00080.jpg",
      "DSC00076.jpg",
      "DSC00075.jpg",
      "DSC00058.jpg",
      "DSC00055.jpg",
      "DSC00046.jpg",
      "DSC00040.jpg",
      "DSC00033.jpg",
      "DSC00026.jpg",
      "DSC00024.jpg",
      "DSC00022.jpg",
      "DSC00010.jpg",
      "DSC00006.jpg",
    ],
  },
  "day-65-68": {
    dates: "दिन 65 से 68 (दिनांक 1 जुलाई से 4 जुलाई 2013)",
    description:
      "ओंगोले (आ.प्र.) से विजयवाड़ा, राजामुन्द्री, विशाखापट्टनम (आ.प्र.), श्रीकाकुलम, बेरहमपुर, भूबनेश्वर, पूरी, कट्टक, भद्रक, बालासोर, जालेश्वर (ओड़ीसा), खड़गपुर, हावड़ा, बेलुर मठ, बर्धमान होते हुए आसनसोल (प.ब.)",
    images: [
      "P1090782.jpg",
      "P1090773.jpg",
      "P1090768.jpg",
      "P1090735.jpg",
      "P1090722.jpg",
      "P1090713.jpg",
      "P1090706.jpg",
      "P1090703.jpg",
      "P1090697.jpg",
      "P1090682.jpg",
      "DSC00921.jpg",
      "DSC00920.jpg",
      "DSC00917.jpg",
      "DSC00916.jpg",
      "DSC00901.jpg",
      "DSC00888.jpg",
      "DSC00885.jpg",
      "DSC00881.jpg",
      "DSC00878.jpg",
      "DSC00876.jpg",
      "DSC00865.jpg",
      "DSC00858.jpg",
      "DSC00822.jpg",
      "DSC00819.jpg",
      "DSC00809.jpg",
      "DSC00808.jpg",
      "DSC00800.jpg",
      "DSC00796.jpg",
      "DSC00795.jpg",
      "DSC00783.jpg",
      "DSC00765.jpg",
      "DSC00758.jpg",
      "DSC00756.jpg",
      "DSC00741.jpg",
      "DSC00739.jpg",
      "DSC00737.jpg",
      "DSC00728.jpg",
      "DSC00716.jpg",
      "DSC00713.jpg",
      "DSC00351.jpg",
      "DSC00331.jpg",
      "DSC00326.jpg",
      "DSC00321.jpg",
      "DSC00287-1.jpg",
      "DSC00283-1.jpg",
      "DSC00279.jpg",
      "DSC00276.jpg",
      "DSC00275.jpg",
      "DSC00262.jpg",
      "DSC00260.jpg",
      "DSC00256.jpg",
      "DSC00240.jpg",
      "DSC00216.jpg",
    ],
  },
  "day-69-71": {
    dates: "दिन 69 से 71 (दिनांक 5 जुलाई से 7 जुलाई 2013)",
    description:
      "आसनसोल (प.ब.) से धनबाद (झा.), वाराणसी, इलाहाबाद, फ़तेहपुर, कानपुर, झाँसी (उ.प्र.), सागर, बेगमगंज, रायसेन, भोपाल, देवास होते हुए इंदौर (म.प्र.)",
    images: [
      "DSC01065.jpg",
      "DSC01058.jpg",
      "DSC01056.jpg",
      "DSC01052.jpg",
      "DSC01049.jpg",
      "DSC01045.jpg",
      "DSC01042.jpg",
      "DSC01040.jpg",
      "DSC01036.jpg",
      "DSC01033.jpg",
      "DSC01029.jpg",
      "DSC01025.jpg",
      "DSC01022.jpg",
      "DSC01021.jpg",
      "DSC01019.jpg",
      "DSC01013.jpg",
      "DSC01009.jpg",
      "DSC01008.jpg",
      "DSC01001.jpg",
      "DSC00998.jpg",
      "DSC00991.jpg",
      "DSC00979.jpg",
      "DSC00978.jpg",
      "DSC00977.jpg",
      "DSC00974.jpg",
      "DSC00969.jpg",
      "DSC00963.jpg",
      "DSC00955.jpg",
      "DSC00952.jpg",
      "DSC00936.jpg",
      "DSC00935.jpg",
      "DSC00931.jpg",
      "DSC00452.jpg",
      "DSC00447.jpg",
      "DSC00442.jpg",
      "DSC00435.jpg",
      "DSC00420.jpg",
      "DSC00413.jpg",
      "DSC00410.jpg",
      "DSC00409.jpg",
      "DSC00405.jpg",
      "DSC00384.jpg",
      "DSC00379.jpg",
    ],
  },
  conclusion: {
    description: "स्वागत एवं समापन समारोह, इंदौर",
    images: [
      "P1090828-1.jpg",
      "P1090827-1.jpg",
      "DSC01360-1.jpg",
      "DSC01359-1.jpg",
      "DSC01354-1.jpg",
      "DSC01344-1.jpg",
      "DSC01335-1.jpg",
      "DSC01305-1.jpg",
      "DSC01304-1.jpg",
      "DSC01296-1.jpg",
      "DSC01279-1.jpg",
      "DSC01276-1.jpg",
      "DSC01271-1.jpg",
      "DSC01214-1.jpg",
      "DSC01201-1.jpg",
      "DSC01199-1.jpg",
      "DSC01188-1.jpg",
      "DSC01174-1.jpg",
      "DSC01166-1.jpg",
      "DSC01164-1.jpg",
      "DSC01148-1.jpg",
      "DSC01144-1.jpg",
      "DSC01138-1.jpg",
      "DSC01132-1.jpg",
      "DSC01124-1.jpg",
      "DSC01110-1.jpg",
      "DSC01103-1.jpg",
      "DSC01098-1.jpg",
      "DSC01096-1.jpg",
      "DSC01093-1.jpg",
      "DSC01090-1.jpg",
      "DSC01086-1.jpg",
      "DSC01077-1.jpg",
      "DSC01074-1.jpg",
      "DSC01071-1.jpg",
      "DSC01068-1.jpg",
      "DSC00514-1.jpg",
      "DSC00512-1.jpg",
      "DSC00511-1.jpg",
      "DSC00476-1.jpg",
      "DSC00471-1.jpg",
    ],
  },
};
