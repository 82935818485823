<template>
  <div class="photo-gallery-detail">
    <GalleryList type="side" v-if="!isMobile" />
    <div>
      <router-link class="back-link" to="/photo-gallery"
        >Back to Gallery List</router-link
      >
      <h2>
        {{ gallery.dates }}
        <small>{{ gallery.description }}</small>
      </h2>
      <LightBox type="gallery" :images="gallery.images.reverse()" />
      <router-link class="back-link" to="/photo-gallery"
        >Back to Gallery List</router-link
      >
    </div>
  </div>
</template>

<script>
import { galleryData } from "@/galleryData";
import GalleryList from "../components/GalleryList";
import LightBox from "@/components/LightBox.vue";
export default {
  name: "PhotoGalleryDetail",
  components: {
    LightBox,
    GalleryList,
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
    gallery() {
      return galleryData[this.$route.params.slug];
    },
  },
};
</script>

<style scoped lang="scss">
.photo-gallery-detail {
  display: flex;
  > div {
    flex-grow: 1;
  }
}
</style>
